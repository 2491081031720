<template>
  <v-form @reset.prevent="cancel" @submit.prevent="login" ref="form" v-model="valid">
    <v-card class="mx-auto pa-4" :class="{'mt-8': desktop}" :outlined="desktop" :flat="mobile" max-width="400">
      <v-card-title class="justify-center pa-2">
        <img :src="logo" alt="Kahu Smart Workplace" class="pa-5 logo" width="100%">
      </v-card-title>
      <div class="text-center pt-3 pb-7">
        <v-btn @click="microsoftLogin" class="sign-in my-1" text>
          <img :src="microsoftSignInImg" alt="Sign in with Microsoft">
        </v-btn>
        <v-btn @click="googleLogin" class="sign-in my-1" text>
          <img :src="googleSignInImg" alt="Sign in with Microsoft">
        </v-btn>
      </div>
      <v-row align="center">
        <v-col>
          <v-divider/>
        </v-col>
        <v-col cols="auto"><span class="grey--text text--lighten-2">or</span></v-col>
        <v-col>
          <v-divider/>
        </v-col>
      </v-row>
      <v-alert color="error" dark dense tile v-if="loginError">{{ errorMessage }}</v-alert>
      <div>
        <v-card-text>
          <v-text-field
              v-model="email.value"
              :rules="email.rules"
              label="Email"
              type="email"
              required
              autocomplete="email"/>
          <v-text-field
              v-model="password.value"
              :rules="password.rules"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              required
              autocomplete="current-password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"/>
        </v-card-text>
        <v-card-actions class="justify-center">
          <template v-if="!disableLogout">
            <v-btn @click="signOut" text>Logout</v-btn>
            <v-spacer/>
          </template>
          <v-btn @click="cancel" text type="reset" v-if="cancelable">Cancel</v-btn>
          <v-btn :disabled="!valid" :progress="working" color="secondary" type="submit">Login</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-form>
</template>

<script>
import {mapActions} from 'vuex';
import {authProviders} from '@/firebase';

export default {
  name: 'LoginCard',
  props: {
    disableLogout: Boolean,
    cancelable: Boolean
  },
  data() {
    return {
      email: {
        rules: this.textRules('Email'),
        value: ''
      },
      password: {
        rules: this.textRules('Password'),
        value: ''
      },
      valid: false,
      working: false,
      loginError: null,
      showPassword: false
    };
  },
  computed: {
    logo() {
      return require('@/assets/Kahu-tagline.svg?as-is');
    },
    googleSignInImg() {
      return require('@/assets/google-sign-in.svg?as-is');
    },
    microsoftSignInImg() {
      return require('@/assets/microsoft-sign-in.svg?as-is');
    },
    errorMessage() {
      if (!this.loginError) {
        return false;
      }

      switch (this.loginError.code) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          return 'Incorrect email or password';
      }
      return this.loginError.message || `${this.loginError}`;
    },
    breakpoints() {
      return this.$vuetify.breakpoint;
    },
    mobile() {
      return this.breakpoints.width < 768;
    },
    desktop() {
      return !this.mobile;
    }
  },
  methods: {
    ...mapActions('auth', ['signOut', 'signIn', 'signInRedirect']),
    login() {
      this.working = true;
      this.loginError = null;
      this.signIn({email: this.email.value, password: this.password.value})
          .then(() => this.$emit('success'))
          .catch(err => this.loginError = err)
          .finally(() => this.working = false);
    },
    googleLogin() {
      this.signInRedirect(authProviders.google);
    },
    microsoftLogin() {
      this.signInRedirect(authProviders.microsoft);
    },
    cancel() {
      this.email.value = '';
      this.password.value = '';
      this.valid = false;
      this.$refs.form.resetValidation();
      this.loginError = null;
      this.$emit('cancel');
    },
    textRules(name) {
      return [
        v => !!v || `${name} is required`,
        v => v.trim().length > 0 || `${name} cannot be empty`
      ];
    }
  }
};
</script>

<style scoped>
  .logo {
    max-height: 150px;
  }

  .sign-in,
  .sign-in img {
    width: 215px;
    height: 41px !important;
  }
</style>
