<template>
  <v-container>
    <login-card @cancel="redirect" @success="redirect" :disable-logout="!hasAuthUser"/>
    <about-line class="py-2"/>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import AboutLine from '../components/AboutLine';
import LoginCard from '../components/LoginCard';

export default {
  name: 'Login',
  components: {LoginCard, AboutLine},
  computed: {
    ...mapGetters('auth', ['hasAuthUser']),
    force() {
      return this.$route.params.force === 'true';
    },
    redirectTo() {
      const redirect = this.$route.params.redirect;
      if (redirect && redirect !== '') {
        return {...redirect};
      } else {
        if (!this.hasAuthUser) {
          // no user, don't go anywhere
          return null;
        }

        // continue the loading process as needed
        return {name: 'loading'};
      }
    }
  },
  watch: {
    hasAuthUser: {
      handler(hasAuthUser) {
        if (!this.force && hasAuthUser) {
          this.redirect();
        }
      },
      immediate: true
    }
  },
  methods: {
    redirect() {
      const location = this.redirectTo;
      if (!location) return;
      this.$router.replace(location)
          .catch(err => this.$logger.debug('redirect aborted', err.message));
    }
  }
};
</script>
